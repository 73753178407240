import React from 'react'
import Helmet from 'react-helmet'
import Contact from '../components/Contact'
import Layout from '../components/layout'
import Address from '../components/Address'

const Page = () => {
  const siteTitle = 'Psychoterapia Mosty - psychoterapeuci we Wrocławiu'
  const siteDescription =
    'Psychoterapia Wrocław, doświadczeni psychoterapeuci. Psychoterapia indywidualna, terapia par oraz terapia rodzinna.'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>Zespół</h2>
          </header>
        </section>

        <section id="jadwiga">
          <h2>
            Jadwiga Wojciechowska
            <br />
          </h2>

          <p style={{ fontWeight: 'bold' }}>
            Założycielka Ośrodka MOSTY.
            <br />
            Certyfikowana psychoterapeutka Polskiego Towarzystwa
            Psychiatrycznego (nr 988).
          </p>

          <p>
            Studia magisterskie ukończyłam na Uniwersytecie Wrocławskim. Jestem
            absolwentką Studium Socjoterapii i Psychoterapii Młodzieży przy
            Krakowskim Centrum Psychodynamicznym oraz 4- letniego akredytowanego
            Kursu Psychoterapii przy Wrocławskiej Fundacji Ochrony Zdrowia
            Psychicznego i Rozwoju Psychoterapii. Doświadczenie kliniczne
            zdobywałam pracując m.in. na Dziennym Oddziale Leczenia Nerwic przy
            DCP oraz we wrocławskim oddziale Krakowskiego Instytutu
            Psychoterapii. Od 2016 roku uczestniczę w seminarium teoretyczno –
            superwizyjnym. W swojej pracy korzystam z rozumienia
            psychodynamicznego oraz psychoanalitycznego. Obecnie pracuję w
            Dziennym Oddziale Psychiatrycznym w Długołęce (Salus) oraz w Ośrodku
            MOSTY. Swoją pracę poddaję regularnej superwizji certyfikowanych
            superwizorów Polskiego Towarzystwa Psychiatrycznego oraz Polskiego
            Towarzystwa Psychoanalitycznego.
          </p>
        </section>

        <section id="alicja">
          <h2>Alicja Kotowska</h2>

          <p style={{ fontWeight: 'bold' }}>
            Pedagog, socjoterapeuta i psychoterapeuta w trakcie procesu
            certyfikacji w Polskim Towarzystwie Psychiatrycznym. Pracuje w
            nurcie psychodynamicznym, systemowym, psychoanalitycznym. Swoją
            pracę poddaje regularnej superwizji. Zajmuje się psychoterapią
            indywidualną dorosłych, dzieci i młodzieży oraz terapią par i
            rodzin.
          </p>

          <p>
            Jestem absolwentką wydziału nauk historycznych i pedagogicznych na
            Uniwersytecie Wrocławskim, ukończyłam studia podyplomowe z zakresu
            Terapii Pedagogicznej, dwuletnie podyplomowe Studium Psychoterapii w
            Krakowskim Centrum Psychodynamicznym w Krakowie oraz 4-letni
            akredytowany Kurs Psychoterapii przy Wrocławskiej Fundacji Ochrony
            Zdrowia Psychicznego i Rozwoju Psychoterapii. Posiadam wieloletnie
            doświadczenie w pracy z dzieckiem i jego rodzicem, jestem
            socjoterapetą, psychoterapeutą, trenerem EEG Biofeedback II stopnia.
          </p>
        </section>

        <section id="agata">
          <h2>Agata Świst</h2>

          <p style={{ fontWeight: 'bold' }}>
            Psycholog, certyfikowana psychoterapeutka Polskiego Towarzystwa
            Psychiatrycznego (nr 643)
          </p>

          <p>
            Z wykształcenia jestem psychologiem i psychoterapeutą. Ukończyłam
            Uniwersytet Wrocławski o specjalności psychologia kliniczna.
            Ukończyłam trzy letnie szkolenie psychoterapeutyczne w nurcie
            Gestalt, studium pomocy psychologicznej, interwencji kryzysowej,
            czteroletnie szkolenie dla osób prowadzących terapię ze sprawcami
            przemocy oraz liczne staże. Ukończyłam 4 letnie studia podyplomowe
            przygotowujące do zawodu psychoterapeuty w Collegium Medicum
            Uniwersytetu Jagiellońskiego w Krakowie.W 2013 roku zdobyłam
            Certyfikat Psychoterapeuty Polskiego Towarzystwa Psychiatrycznego(nr
            certyfikatu 643) uprawniający do samodzielnego stosowania
            psychoterapii. Od 5 lat uczestniczę w seminarium analityczno –
            superwizyjnym. Posiadam ponad 20 letnie doświadczenie w prowadzeniu
            terapii indywidualnej jak i grupowej. Współpracowałam z ośrodkiem
            leczenia uzależnień, domem dziecka, szkołami, zakładami karnymi i
            wojskiem.Stale podnoszę swoje kwalifikacje, biorę udział w
            szkoleniach i regularnie poddaję swoją pracę superwizji. Posiadam
            również uprawnienia do wydawania pozwoleń na posiadanie broni.
            Jestem członkiem Polskiego Towarzystwa Psychiatrycznego. Prowadząc
            terapię pracuję głównie w nurcie psychodynamicznym, w zależności od
            problemu pacjenta, używam innych technik
          </p>
        </section>

        <section id="katarzyna">
          <h2>Katarzyna Szuba</h2>
        </section>

        <section id="joanna">
          <h2>Joanna Szymańska</h2>

          <p style={{ fontWeight: 'bold' }}>
            Psycholog, psychoterapeuta w trakcie certyfikacji
          </p>

          <p>
            Jestem absolwentką psychologii na Uniwersytecie Wrocławskim, a
            stopień doktora nauk społecznych w zakresie psychologii uzyskałam w
            Instytucie Psychologii Uniwersytetu Gdańskiego.
          </p>

          <p>
            Ukończyłam Kurs Terapii Systemowej Wielkopolskiego Towarzystwa
            Terapii Systemowej w Poznaniu (Poziom Podstawowy i Zaawansowany),
            obecnie jestem w trakcie Szkoły Psychoterapii Dzieci i Młodzieży w
            Warszawie, które to szkolenie realizowane jest przez dwa
            współpracujące zespoły: Laboratorium Psychoedukacji i Ośrodek
            Regeneracja. Posiadam ponad 15 letnie doświadczenie w pracy
            klinicznej zarówno z dziećmi, młodzieżą, jak i osobami dorosłymi. W
            swojej pracy opieram się na założeniach terapeutycznego paradygmatu
            systemowego i psychodynamicznego.
          </p>

          <p>
            Obecnie związana jestem także z Centrum Zdrowia Psychicznego Dzieci
            i Młodzieży Dom na Drzewie we Wrocławiu.
          </p>
          <p>
            Swoją pracę poddaję regularnej superwizji certyfikowanych
            superwizorów Polskiego Towarzystwa Psychiatrycznego oraz Polskiego
            Towarzystwa Psychologicznego. Należę do Dolnośląskiego
            Stowarzyszenia Psychoterapeutów.{' '}
          </p>
        </section>

        <section id="three">
          <Address />
        </section>
      </div>
    </Layout>
  )
}

export default Page
